import { Box } from '@mui/material';

// ==============================|| HEADER ||============================== //

const Header = () => {
    return (
        <Box
            sx={{
                display: 'none', // 隐藏 Header，包括 Toolbar
            }}
        >
            {/* 其他内容 */}
        </Box>
    );
};

export default Header;
